<template>
  <app-event event-id="evraz-no-limits" auth="login-only" :mapping="mapping">
    <template v-slot:logo>
      <div
        style="
          padding: 8px;
          border-radius: 8px;
          background-color: white;
          margin: 16px;
        "
      >
        <v-img :src="require('./assets/logo.png')" class="ma-4" />
      </div>
    </template>
  </app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import Quest from "./Quest";

export default {
  name: "NoLimitsPageEvent",
  components: {
    AppEvent,
  },
  data() {
    return {
      mapping: {
        quest: Quest,
      },
    };
  },
};
</script>
