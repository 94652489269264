<template>
  <div>
    <v-sheet
      color="white"
      class="pa-6 d-flex flex-column justify-center align-center"
    >
      <div class="text-h3 ma-6 pa-6" style="color: #e05c4b">
        Эффективность выполнения заданий
      </div>
      <v-divider light></v-divider>
      <div class="d-flex justify-center flex-column flex-md-row align-center">
        <div class="d-flex flex-column ma-6 pa-6 align-center justify-center">
          <div class="text-h4" style="color: #c27f26">Индивидуальные</div>
          <div class="ma-6 pa-6">
            <v-progress-circular
              :rotate="-90"
              :size="300"
              :width="15"
              :value="individual"
              color="#C27F26"
            >
              <span style="font-size: 40px">
                {{ `${individual.toFixed(2)}%` }}
              </span>
            </v-progress-circular>
          </div>
        </div>
        <v-divider vertical light></v-divider>
        <div class="d-flex flex-column ma-6 pa-6 align-center justify-center">
          <div class="text-h4" style="color: #e05c4b">Командные</div>
          <div class="ma-6 pa-6">
            <v-progress-circular
              :rotate="-90"
              :size="300"
              :width="15"
              :value="team"
              color="primary"
            >
              <span style="font-size: 40px">
                {{ `${team.toFixed(2)}%` }}
              </span>
            </v-progress-circular>
          </div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NoLimitsStandings",
  props: ["items", "my-points", "my-game-id"],
  computed: {
    ...mapGetters({
      meta: "event/eventMeta",
    }),
    team() {
      const strValue = this.stats.team || "0";
      const floatValue = Number.parseFloat(strValue);
      return Number.isNaN(floatValue) ? 0 : floatValue;
    },
    individual() {
      const strValue = this.stats.individual || "0";
      const floatValue = Number.parseFloat(strValue);
      return Number.isNaN(floatValue) ? 0 : floatValue;
    },
    stats() {
      return (this.meta && this.meta.stats) || {};
    },
  },
};
</script>
